import styled, {css} from 'styled-components'
import {ModalBody, ModalHeader} from 'src/components/modal-atoms/ModalAtoms.tsx'

export const StyledModalHeader = styled(ModalHeader)(
    ({theme}) => css`
        display: grid;
        grid-template-columns: 36px minmax(0, 1fr) 36px;
        justify-items: center;
        align-items: center;
        border-bottom: 1px solid ${theme.palette.neutral[300]};
        & p{
            color: ${theme.palette.neutral[900]}};
        }
    `
)

export const StyledModalBody = styled(ModalBody)(
    ({theme: {palette, typography, spacing}}) => css`
        gap: ${spacing}px;
        & h3 {
            color: ${palette.neutral[900]};
            ${typography.textXl};
            font-weight: 500;
        }
        
        & p{
            color: ${palette.neutral[900]}};
        }
    `
)
