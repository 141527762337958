import {useAddItemFlags} from 'src/features/admin-services/services/useAddItemFlags.ts'
import {useRemoveItemFlags} from 'src/features/admin-services/services/useRemoveItemFlags.ts'
import {useAddSectionFlags} from 'src/features/admin-services/services/useAddSectionFlags.ts'
import {useRemoveSectionFlags} from 'src/features/admin-services/services/useRemoveSectionFlags.ts'
import {HttpEditItemFlags} from 'src/features/admin-services/services/adminServices.http.ts'

interface UseGetFlagActionHookProps {
    isSection?: boolean
    urlParams: HttpEditItemFlags['urlParams']
}

export const useGetFlagAction = ({isSection, urlParams}: UseGetFlagActionHookProps) => {
    const addQuestionFlags = useAddItemFlags(urlParams)
    const removeQuestionFlags = useRemoveItemFlags(urlParams)

    const addItemFlags = useAddSectionFlags(urlParams)
    const removeItemFlags = useRemoveSectionFlags(urlParams)

    return {
        addFlags: isSection ? addItemFlags : addQuestionFlags,
        removeFlags: isSection ? removeItemFlags : removeQuestionFlags
    }
}
