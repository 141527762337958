import styled, {css} from 'styled-components'
import {SelectItem, SelectTrigger} from 'src/components/select-atoms/SelectAtoms.tsx'

export const StyledSelectTrigger = styled(SelectTrigger)(
    ({theme: {palette}}) => css`
        & h4 {
            text-decoration: underline;
        }
        & div {
            align-items: end;
        }
        &:not(:disabled):hover,
        &:focus,
        &[aria-expanded='true'] {
            outline: none;
            box-shadow: none;
        }
        &:disabled {
            background-color: ${palette.neutral['100']};
        }
    `
)

export const StyledSelectItem = styled(SelectItem)(
    ({theme: {spacing, typography, palette}}) => css`
        display: flex;
        gap: ${spacing * 2}px;
        & h4 {
            ${typography.textMd}
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
        p {
            color: ${palette.neutral[600]};
        }
        &[aria-selected='true'] {
            background-color: ${palette.neutral['100']};
        }
    `
)
