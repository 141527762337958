import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {HelpCircleIcon, ThumbsDownIcon, ThumbsUpIcon} from 'src/components/icon'
import {FC} from 'react'
import {useTheme} from 'styled-components'
import {Tooltip} from 'src/components/tooltip/Tooltip.tsx'
import {useTranslation} from 'react-i18next'
import {ServiceVettingStatus} from 'src/features/admin-services/types.ts'

interface AdminServiceVettingStatusSelectorProps {
    value: ServiceVettingStatus | null
    onChange: (service: ServiceVettingStatus) => void
    isLoading?: boolean
    disabled?: boolean
}

export const AdminServiceVettingStatusSelector: FC<AdminServiceVettingStatusSelectorProps> = ({
    value,
    onChange,
    disabled
}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <Flexbox gap={3}>
            <Tooltip
                rootProps={{open: value == 'accepted' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('admin_services:accepted')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'accepted' ? 'primarySuccess' : 'secondary'}
                    shape={value == 'accepted' ? 'rectangle' : 'square'}
                    onClick={() => value != 'accepted' && onChange('accepted')}
                >
                    <ThumbsUpIcon color={!value ? palette.success['500'] : undefined} />
                    {value == 'accepted' && t('admin_services:accepted')}
                </Button>
            </Tooltip>
            <Tooltip
                rootProps={{open: value == 'declined' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('admin_services:rejected')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'declined' ? 'primaryDanger' : 'secondary'}
                    shape={value == 'declined' ? 'rectangle' : 'square'}
                    onClick={() => value != 'declined' && onChange('declined')}
                >
                    <ThumbsDownIcon color={!value ? palette.danger['500'] : undefined} />
                    {value == 'declined' && t('admin_services:rejected')}
                </Button>
            </Tooltip>
            <Tooltip
                rootProps={{open: value == 'back_to_draft' ? false : undefined}}
                isOpenOnClickEnabled={false}
                content={t('admin_services:back_to_draft')}
                triggerProps={{asChild: true}}
            >
                <Button
                    style={{height: '36px'}}
                    disabled={!!disabled}
                    size={'sm'}
                    variant={value == 'back_to_draft' ? 'primaryBlue' : 'secondary'}
                    shape={value == 'back_to_draft' ? 'rectangle' : 'square'}
                    onClick={() => value != 'back_to_draft' && onChange('back_to_draft')}
                >
                    <HelpCircleIcon color={!value ? palette.blue['500'] : undefined} />
                    {value == 'back_to_draft' && t('admin_services:back_to_draft')}
                </Button>
            </Tooltip>
        </Flexbox>
    )
}
