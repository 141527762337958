import styled, {css, DefaultTheme} from 'styled-components'

type StyledIconContainerProps = {
    $size: 'md' | 'lg'
    $variant: 'neutral' | 'danger'
}

const makeIconContainerVariantStyle = (theme: DefaultTheme) =>
    ({
        neutral: css`
            border-color: ${theme.palette.neutral[600]};
            background: transparent;
        `,
        danger: css`
            border-color: ${theme.palette.danger[600]};
            background: ${theme.palette.danger[600]};
        `
    } as const satisfies Record<NonNullable<StyledIconContainerProps['$variant']>, ReturnType<typeof css>>)

export const StyledIconContainer = styled('div')<StyledIconContainerProps>(
    ({$size, $variant, theme}) => css`
        width: ${$size == 'lg' ? '48px' : '40px'};
        height: ${$size == 'lg' ? '48px' : '40px'};
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        ${makeIconContainerVariantStyle(theme)[$variant]};
        svg {
            width: ${$size == 'lg' ? '24px' : '20px'};
            height: ${$size == 'lg' ? '24px' : '20px'};
        }
    `
)
