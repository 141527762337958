import {StyledIconContainer} from './style.ts'
import {FC, HTMLAttributes, ReactNode} from 'react'

interface IconContainerProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    size?: 'md' | 'lg'
    variant?: 'neutral' | 'danger'
}
export const IconContainer: FC<IconContainerProps> = ({children, size = 'md', variant = 'neutral', ...rest}) => {
    return (
        <StyledIconContainer $variant={variant} $size={size} {...rest}>
            {children}
        </StyledIconContainer>
    )
}

IconContainer.displayName = 'IconContainer'
