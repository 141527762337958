import {FlagsSelect} from 'src/features/admin-services/components/flags-popover/FlagsSelect.tsx'
import {Badge, BadgeProps} from 'src/components/badge/Badge.tsx'
import {EditFlagsItemUrlParam, EditFlagsSectionUrlParam, Flag} from 'src/features/admin-services/types.ts'
import {FC} from 'react'
import {StyledAdminServiceVettingFlagsContainer} from 'src/features/admin-services/components/admin-service-vetting-flags/style.ts'
import {XCloseIcon} from 'src/components/icon'
import {Button} from 'src/components/button/Button.tsx'
import {useGetFlagAction} from 'src/features/admin-services/hooks/useGetFlagAction.ts'
import {useTranslation} from 'react-i18next'
import {PopoverContentProps} from '@radix-ui/react-popover'

type AdminServiceVettingFlagsProps = {
    itemId: number
    flags: Flag[]
    editFlagServiceSection: EditFlagsSectionUrlParam
    editFlagServiceItem: EditFlagsItemUrlParam
    serviceId: number
    sectionId?: number
    flagPosition?: 'left' | 'right'
    isSection?: boolean
    badgeVariant?: BadgeProps['variant']
    popoverAlign?: PopoverContentProps['align']
    disabled?: boolean
    types?: Flag['type'][]
}

export const AdminServiceVettingFlags: FC<AdminServiceVettingFlagsProps> = ({
    itemId,
    flags,
    editFlagServiceSection,
    sectionId,
    serviceId,
    flagPosition = 'right',
    isSection,
    badgeVariant = 'blue',
    popoverAlign,
    disabled,
    editFlagServiceItem,
    types
}) => {
    const {t} = useTranslation()
    const {removeFlags} = useGetFlagAction({
        isSection,
        urlParams: {serviceId, sectionId, itemId, section: editFlagServiceSection, item: editFlagServiceItem}
    })
    return (
        <StyledAdminServiceVettingFlagsContainer $flagPosition={flagPosition} gap={2} fullWidth>
            <FlagsSelect
                align={popoverAlign}
                serviceId={serviceId}
                editFlagServiceSection={editFlagServiceSection}
                sectionId={sectionId}
                itemId={itemId}
                selectedFlagsId={flags.map(flag => flag.id)}
                isSection={isSection}
                disabled={!!disabled}
                types={types || []}
                editFlagServiceItem={editFlagServiceItem}
            />
            {flags.map(flag => (
                <Badge
                    disabled={!!disabled}
                    style={{wordBreak: 'break-word'}}
                    key={flag.id}
                    shape={'rectangle'}
                    variant={badgeVariant}
                >
                    {t(`admin_services:flags_key_name:${flag.key_name}`)}
                    <Button
                        shape={'text'}
                        variant={'tertiary'}
                        onClick={() => removeFlags.mutate({flags: [flag]})}
                        disabled={disabled}
                    >
                        <XCloseIcon />
                    </Button>
                </Badge>
            ))}
        </StyledAdminServiceVettingFlagsContainer>
    )
}
