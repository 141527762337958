import {FC} from 'react'
import {Checkbox} from 'src/components/checkbox/Checkbox.tsx'
import {StyledFlagsPopoverItem} from 'src/features/admin-services/components/flags-popover/style.ts'
import {EditFlagsItemUrlParam, EditFlagsSectionUrlParam, FlagWithLabel} from 'src/features/admin-services/types.ts'
import {useGetFlagAction} from 'src/features/admin-services/hooks/useGetFlagAction.ts'

interface FlagsPopoverItemProps {
    itemId: number
    flag: FlagWithLabel
    checked?: boolean
    editFlagServiceSection: EditFlagsSectionUrlParam
    editFlagServiceItem: EditFlagsItemUrlParam
    serviceId: number
    sectionId?: number
    isSection?: boolean
}

export const FlagsPopoverItem: FC<FlagsPopoverItemProps> = ({
    flag,
    checked = false,
    itemId,
    editFlagServiceSection,
    sectionId,
    serviceId,
    editFlagServiceItem,
    isSection
}) => {
    const {addFlags, removeFlags} = useGetFlagAction({
        isSection,
        urlParams: {serviceId, sectionId, itemId, section: editFlagServiceSection, item: editFlagServiceItem}
    })

    const handleUpdateFlag = () => {
        const mutationFunction = checked ? removeFlags : addFlags
        mutationFunction.mutate({flags: [flag]})
    }

    return (
        <StyledFlagsPopoverItem key={flag.id} gap={2} justify="space-between">
            <Checkbox
                disabled={addFlags.isLoading || removeFlags.isLoading}
                id={flag.id.toString()}
                checked={checked}
                onChange={handleUpdateFlag}
                label={flag.label}
            />
        </StyledFlagsPopoverItem>
    )
}
