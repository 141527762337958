import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {HttpEditItemFlags, httpRemoveServiceItemFlags} from 'src/features/admin-services/services/adminServices.http.ts'
import {AdminService, Flag} from 'src/features/admin-services/types.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'
import {remapDataForSetQuestionFlagQuery} from 'src/features/admin-services/utils.ts'

export const useRemoveItemFlags = (
    urlParams: HttpEditItemFlags['urlParams'],
    options?: Omit<UseMutationOptions<unknown, unknown, {flags: Flag[]}>, 'mutationFn'>
) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            httpRemoveServiceItemFlags({
                urlParams,
                payload: {flags: payload.flags.map(flag => flag.id)},
                params: {
                    response_type: 'small'
                },
                headers: makeHttpAuthorizationHeader(adminAccessToken)
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<AdminService>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<AdminService>(
                    {queryKey},
                    remapDataForSetQuestionFlagQuery({
                        prevData: previousData,
                        params: urlParams,
                        variables,
                        removeFlags: true
                    })
                )
            }
            return previousData
        },
        ...options
    })
}
