import {AdminService} from 'src/features/admin-services/types'
import {StyledAdminImageCardFrame, StyledAdminServiceImageCard, StyledAdminServiceImageTitle} from './style'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {ThumbsDownIcon, ThumbsUpIcon} from 'src/components/icon'
import {useTranslation} from 'react-i18next'
import {useUpdateIsApprovedGalleryImg} from 'src/features/admin-services/services/useUpdateIsApprovedGalleryImg.ts'
import {AdminServiceVettingFlags} from 'src/features/admin-services/components/admin-service-vetting-flags/AdminServiceVettingFlags.tsx'

export const AdminServiceVettingGalleryItems = ({
    galleryItem,
    galleryId,
    disableAction
}: {
    galleryId: number
    galleryItem: AdminService['gallery']['images'][number]
    disableAction?: boolean
}) => {
    const {t} = useTranslation()
    const updateIsApprovedGalleryImg = useUpdateIsApprovedGalleryImg({
        serviceId: galleryItem.submission_id,
        imgId: galleryItem.id,
        galleryId
    })
    return (
        <StyledAdminImageCardFrame key={galleryItem.id} direction="column" gap={4}>
            <StyledAdminServiceImageCard src={galleryItem.url} />
            <Flexbox gap={2} direction="column" fullWidth>
                <StyledAdminServiceImageTitle>{galleryItem.name}</StyledAdminServiceImageTitle>
                <Flexbox gap={3} fullWidth>
                    <Button
                        fullWidth
                        style={{whiteSpace: 'nowrap'}}
                        disabled={disableAction}
                        size={'sm'}
                        variant={
                            galleryItem.is_approved === null || !galleryItem.is_approved
                                ? 'secondary'
                                : 'primarySuccess'
                        }
                        onClick={() =>
                            galleryItem.is_approved != true && updateIsApprovedGalleryImg.mutate({is_approved: true})
                        }
                    >
                        <ThumbsUpIcon />
                        {t('admin_services:accept')}
                    </Button>
                    <Button
                        fullWidth
                        style={{whiteSpace: 'nowrap'}}
                        disabled={disableAction}
                        size={'sm'}
                        variant={
                            galleryItem.is_approved === null || galleryItem.is_approved ? 'secondary' : 'primaryDanger'
                        }
                        onClick={() =>
                            galleryItem.is_approved != false && updateIsApprovedGalleryImg.mutate({is_approved: false})
                        }
                    >
                        <ThumbsDownIcon />
                        {t('admin_services:reject')}
                    </Button>
                </Flexbox>
                {galleryItem.is_approved == false && (
                    <AdminServiceVettingFlags
                        editFlagServiceSection={'gallery'}
                        editFlagServiceItem={'images'}
                        sectionId={galleryId}
                        serviceId={galleryItem.submission_id}
                        itemId={galleryItem.id}
                        flags={galleryItem.flags}
                        disabled={disableAction}
                        types={['gallery_image']}
                    />
                )}
            </Flexbox>
        </StyledAdminImageCardFrame>
    )
}
