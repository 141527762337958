export const ROUTES = {
    INDEX: '/',
    HOME: '/home',
    SETTINGS: '/settings',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/password/recovery/:token',
    CREATE_USER: '/validate/photographer/:token',
    PAGE_NOT_FOUND: '/page-not-found',
    TRANSACTION_HISTORY: '/transactions',
    SINGLE_ASSIGNMENT_LEGACY: '/assignments-legacy/:code',
    SINGLE_ASSIGNMENT: '/assignments/:code',
    SINGLE_ASSIGNMENT_NO_ID: '/assignments',
    SINGLE_TASK: '/tasks/:code', //due to backend error (sms sent with wrong links)
    ADMIN_LOGIN: '/admin/login',
    ADMIN: '/admin',
    ADMIN_LOGOUT: '/admin/logout',
    ADMIN_ASSIGNMENTS: '/admin/assignments',
    ADMIN_PARTNERS: '/admin/partners',
    ADMIN_MATTERPORT: '/admin/matterport',
    ADMIN_MATTERPORT_UPLOADER: '/admin/matterport/uploader/:id',
    ADMIN_MATTERPORT_TASK: '/admin/matterport/task/:id',
    ADMIN_PAYMENTS: '/admin/payments',
    ADMIN_PAYMENTS_PROCESS: '/admin/payments/process',
    ADMIN_PAYMENTS_GENERATE: '/admin/payments/generate',
    ADMIN_HOST_APPROVAL: '/admin/host-approval',
    ADMIN_HOST_APPROVAL_NEW: '/admin/host-approval/new',
    ADMIN_HOST_APPROVAL_PENDING_HOST_APPROVAL: '/admin/host-approval/pending-host-approval',
    ADMIN_HOST_APPROVAL_READY_FOR_REVIEW: '/admin/host-approval/ready-for-review',
    ADMIN_HOST_APPROVAL_COMPLETED: '/admin/host-approval/completed',
    ADMIN_HOST_APPROVAL_TASK: '/admin/host-approval/tasks/:taskId',
    ADMIN_SERVICE_VETTING: '/admin/services/:serviceId',
    ADMIN_HOSTS: '/admin/hosts',
    CALENDAR: '/calendar'
} as const

export const FILTER_STATUSES = {
    PENDING_DATE: 'pending_shoot_date',
    PENDING_ASSETS: 'pending_assets',
    ASSETS_REJECTED: 'assets_rejected'
} as const

export const ASSIGNMENT_STATUSES = {
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    PENDING: 'pending',
    AUTO_CANCELLED: 'auto_cancelled',
    SOFT_CANCELLED: 'soft_cancelled',
    HARD_CANCELLED: 'hard_cancelled'
} as const

export const TASK_STATUSES = {
    ACCEPTED: 'accepted',
    ASSETS_IN_REVIEW: 'assets_in_review',
    AUTO_CANCELLED: 'auto_cancelled',
    COMPLETED: 'completed',
    HARD_CANCELLED: 'hard_cancelled',
    NEEDS_MORE_ASSETS: 'needs_more_assets',
    OVERDUE: 'overdue',
    PENDING: 'pending',
    PENDING_ASSETS: 'pending_assets',
    PENDING_PAYMENT: 'pending_payment',
    SCHEDULED: 'scheduled',
    SOFT_CANCELLED: 'soft_cancelled',
    WAITLISTED: 'waitlisted'
} as const

export const POST_PRODUCTION_STATUSES = {
    INITIAL_QA_FAILED: 'initial_qa_failed',
    NEEDS_TO_BE_ASSIGNED: 'needs_to_be_assigned',
    FINAL_QA_FAILED: 'final_qa_failed',
    ASSIGNED: 'assigned',
    INITIAL_QA: 'initial_qa',
    WORK_IN_PROGRESS: 'work_in_progress',
    READY_FOR_FINAL_QA: 'ready_for_final_qa',
    READY_FOR_PRE_UPLOAD: 'ready_for_pre_upload',
    READY_FOR_UPLOAD: 'ready_for_upload',
    UPLOAD_IN_PROGRESS: 'upload_in_progress',
    UPLOAD_ISSUE: 'upload_issue',
    UPLOAD_COMPLETED: 'upload_completed'
} as const

export const QUOTE_STATUSES = {
    SENT: 'sent',
    SIGNED: 'signed',
    EXPIRED: 'expired',
    DECLINED: 'declined',
    RENEWED: 'renewed',
    MARKET_CLOSED: 'market_closed',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed'
} as const

export const QUOTE_LOGS_STATUSES = {
    PRODUCTION: 'production',
    POST_PRODUCTION: 'post-production',
    QUOTE: 'quote'
} as const

export const MATTERPORT_STATUSES = {
    UPLOAD_MEDIABOX: 'upload_to_mediabox',
    READY_QA: 'ready_for_QA',
    PENDING_APPROVAL: 'pending_host_approval',
    REVISION: 'matterport_revision',
    READY_PUBLISH: 'ready_for_upload',
    COMPLETED: 'completed',
    CANCELED: 'canceled'
} as const

export const PHOTOGRAPHER_STATUSES = {
    ACTIVE_AVAILABLE: 'active_available',
    ACTIVE_UNAVAILABLE: 'active_unavailable',
    SUSPENDED: 'suspended',
    DEACTIVATED: 'deactivated'
} as const

export const ALERT_LEVELS = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
} as const

export const MARKET_TYPES = {
    REGIONS: 'regions',
    COUNTRIES: 'countries',
    MARKETS: 'geos'
} as const

export const RESPONSE_TYPES = {
    SMALL: 'small',
    REGULAR: 'regular',
    EXTENDED: 'extended',
    FINDER: 'finder'
} as const

export const SERVICE_TYPE = {
    HOMES_PRODUCTION: 1,
    WALKTHROUGH: 3
} as const

export const SERVICE_MEDIAS = {
    PHOTO: 1,
    VIDEO: 2,
    MATTERPORT: 3,
    INSPECTION: 4
} as const

export const SERVICE_CATEGORIES = {
    THEMED: 6,
    EXPERIENCES: 4,
    PRIVATE_ROOM: 39
} as const

export const ENTITY_TYPES = {
    HOMES: 1,
    EXPERIENCES: 2
} as const

export const CHATBOT_SOURCES = {
    CHATBOT: 'Chatbot',
    USER: 'User'
} as const

export const CHATBOT_CANCELLATION_REASONS = {
    CREATOR_DONT_MATCHING_AVAIBILITIES: 2,
    CREATOR_LOCATED_FAR_AWAY: 3,
    CREATOR_NOT_AVAILABLE_ANYMORE: 4,
    CREATOR_DONT_FEEL_COMFORTABLE: 5,
    CREATOR_ACCEPTED_MISTAKE: 6,
    CREATOR_COVID_19: 7,
    CREATOR_OTHER: 8,
    HOST_NEVER_RESPONDED: 10,
    HOST_STOPPED_RESPONDING: 11,
    HOST_NOT_INTERESTED_ANYMORE: 12,
    HOST_NO_SHOW: 13,
    HOST_LISTING_NOT_READY: 14,
    HOST_UNAVAILABLE_3_MONTHS: 15,
    HOST_NOT_COMFORTABLE_6_MONTHS: 16,
    HOST_RESCHEDULED_3_TIMES: 17,
    HOST_OTHER: 18,
    HOST_SIGNED_MISTAKE: 19,
    HOST_PRICE_HIGH: 20,
    HOST_CHANGED_MIND: 21,
    HOST_IM_NOT_INTERESTED_ANYMORE: 22,
    HOST_FOUND_ANOTHER_PHOTOGRAPHER: 23,
    HOST_MY_LISTING_NOT_READY: 24,
    HOST_NOT_READY: 25,
    HOST_PHOTOGRAPHER_UNRESPONSIVE: 26,
    HOST_DONT_FEEL_COMFORTABLE_PHOTOGRAPHER: 27,
    HOST_LISTING_DOESNT_EXIST_ANYMORE: 28,
    HOST_LISTING_ALREADY_PHOTOGRAPHED: 29
} as const

export const PAGE_LIMIT = 50

export const SHOOT_DETAILS = {
    DURATION: 2 //in hours
} as const

export const PAYMENTS_CSV_TYPE = {
    PAYOUT: 'payout',
    ADJUSTMENT: 'adjustment',
    BONUS: 'bonus'
} as const

export const PAYMENTS_TYPES_ARRAY = [
    {label: 'Payout', value: PAYMENTS_CSV_TYPE.PAYOUT},
    {label: 'Adjustment', value: PAYMENTS_CSV_TYPE.ADJUSTMENT},
    {label: 'Bonus', value: PAYMENTS_CSV_TYPE.BONUS}
] as const

export const PAYMENTS_STATS = {
    PENDING_PAYMENT: 'pending_payment',
    PAID_LAST_WEEK: 'paid_last_week',
    PROCESSED_LAST_WEEK: 'processed_last_week'
} as const

export const PAYMENTS_FORM_MODEL = {
    PAYMENT_DATE: 'payment_date',
    PAYMENT_TYPE: 'payment_type',
    FILES: 'files'
} as const

export const APP_VERSION = '1.0'
export const GTM_ID = 'GTM-5GK5DK7'

export const scores = [1, 2, 3, 4, 5]

export const GUIDE_LINK = 'https://youtu.be/4IMHov7PkPU'

export const TABLE_ALIGNMENTS = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right'
} as const

export const PHOTOGRAPHERS_FILTER_OPTIONS = {
    INSPECTORS: 'inspectors',
    HOMES_PRODUCTION: 'home_production'
} as const

export const ASSIGNMENT_LOGIC_TOOLTIP = {
    FORCED_MATCH: 'Forced Match'
} as const

export const DEFAULT_LIMIT = 30

export const RATING_MODAL = {
    NEW_ASSIGNMENT_RATING_CLOSED_TIME_LS: 'new_assignment_rating_closed_time',
    TRANSACTION_RATING_CLOSED_TIME_LS: 'transactions_rating_closed_time',
    NEW_ASSIGNMENT_RATING_DONE: 'new_assignment_rating_done',
    TRANSACTION_RATING_DONE: 'transactions_rating_done'
} as const
