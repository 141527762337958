import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {captureException} from '@sentry/react'
import {HttpSubmitService, httpSubmitService} from 'src/features/admin-services/services/adminServices.http.ts'
import {AdminService} from 'src/features/admin-services/types.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'

export const useSubmitService = (
    urlParams: HttpSubmitService['urlParams'],
    options?: UseMutationOptions<AdminService, unknown, HttpSubmitService['payload']>
) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpSubmitService({
                    urlParams,
                    payload,
                    params: {
                        response_type: 'extended'
                    },
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: AdminService,
                onZodError: captureException
            }),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]}, data)
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_VERSIONS, urlParams]})
            options?.onSuccess?.(data, variables, context)
        }
    })
}
