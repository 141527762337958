import styled, {css} from 'styled-components'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'

export const StyledSubHeaderBannerWrapper = styled.div(
    ({theme: {palette}}) => css`
        background: ${palette.danger[50]};
        height: auto;
        width: 100%;
    `
)

export const StyledSubHeaderBannerBody = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px 0;
        flex-shrink: inherit;
        min-height: 64px;
        & svg {
            fill: ${palette.neutral.white};
        }
    `
)

export const StyledSubHeaderBannerText = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        & > h3 {
            color: ${palette.neutral[700]};
            font-weight: 500;
        }

        & p {
            color: ${palette.neutral[600]};
            ${typography.textSm};
            font-weight: 400;
        }
    `
)
