import styled, {css} from 'styled-components'

export const StyledStyledAdminServiceSectionTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textLg};
        font-weight: 500;
    `
)
export const StyledAdminServiceParagraphTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 500;
    `
)
export const StyledAdminServiceLabelDetails = styled.span(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        display: block;
        color: ${palette.neutral['600']};
        font-weight: 500;
    `
)
export const StyledAdminServiceParagraph = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textSm};
        word-break: break-word;
    `
)

export const StyledAdminServiceDisabledContainer = styled.div<{$isDisabled: boolean}>(
    ({$isDisabled}) => css`
        ${$isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.4;
        `}
    `
)
