import {StyledBadge} from 'src/components/badge/style'
import {HTMLAttributes} from 'react'

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
    variant?: 'neutral' | 'primary' | 'blue' | 'grayModernDark' | 'grayModernLight' | 'danger' | 'success' | 'warning'
    size?: 'sm' | 'md' | 'lg'
    shape?: 'roundedRectangle' | 'circle' | 'rectangle' | 'square'
    disabled?: boolean
}

export const Badge = ({
    variant = 'neutral',
    shape = 'roundedRectangle',
    size = 'md',
    disabled,
    ...rest
}: BadgeProps) => {
    return <StyledBadge $shape={shape} $disabled={disabled} $variant={variant} $size={size} {...rest} />
}
