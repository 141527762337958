import {useTranslation} from 'react-i18next'
import {FC, useMemo, useState} from 'react'
import {
    StyledFlagsPopoverContent,
    StyledFlagsPopoverItem,
    StyledFlagsPopoverTrigger,
    StyledFlagsPopoverTriggerButton,
    StyledInputSearchFlag,
    StyledPopoverFlagsList,
    StyledSearchFlag
} from 'src/features/admin-services/components/flags-popover/style.ts'
import {Divider} from 'src/components/divider/Divider.tsx'
import {EmptyFlagsResult} from 'src/features/admin-services/components/flags-popover/EmptyFlagsResult.tsx'
import {ScrollArea} from 'src/components/scroll-area/ScrollArea.tsx'
import {Scrollbar} from 'src/components/scrollbar/Scrollbar.tsx'
import {FlagsPopoverItem} from 'src/features/admin-services/components/flags-popover/FlagPopoverItem.tsx'
import {AlertTriangleIcon, FileX02Icon, PlusIcon, SearchLgIcon} from 'src/components/icon'
import {useGetFlags} from 'src/features/admin-services/services/useGetFlags.ts'
import {
    EditFlagsItemUrlParam,
    EditFlagsSectionUrlParam,
    Flag,
    FlagWithLabel
} from 'src/features/admin-services/types.ts'
import {PopoverContentProps, Portal, Root, Trigger} from '@radix-ui/react-popover'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'

interface FlagsPopoverProps {
    types: Flag['type'][]
    align?: PopoverContentProps['align']
    itemId: number
    selectedFlagsId: number[]
    editFlagServiceSection: EditFlagsSectionUrlParam
    editFlagServiceItem: EditFlagsItemUrlParam
    serviceId: number
    sectionId?: number
    isSection?: boolean
    disabled?: boolean
}

export const FlagsSelect: FC<FlagsPopoverProps> = ({
    types,
    align = 'start',
    itemId,
    selectedFlagsId,
    sectionId,
    editFlagServiceSection,
    serviceId,
    isSection,
    editFlagServiceItem,
    disabled
}) => {
    const [search, setSearch] = useState('')
    const {t} = useTranslation()

    const flagsQuery = useGetFlags({
        response_type: 'extended',
        types
    })

    const searchFlagByLabel = (flags: FlagWithLabel[], inputSearch: string) =>
        flags.filter(flag => flag.label.toLowerCase().includes(inputSearch.toLowerCase()))

    const flagsList = useMemo(() => {
        const remappedFlags = flagsQuery.data
            ? flagsQuery.data?.map(data => ({...data, label: t(`admin_services:flags_key_name:${data.key_name}`)}))
            : []
        return searchFlagByLabel(remappedFlags, search)
    }, [flagsQuery])

    return (
        <Root onOpenChange={visibility => visibility && setSearch('')}>
            <Trigger asChild>
                <StyledFlagsPopoverTrigger>
                    <StyledFlagsPopoverTriggerButton disabled={!!disabled} variant={'secondaryBlue'}>
                        <PlusIcon />
                        {t('admin_services:flag')}
                    </StyledFlagsPopoverTriggerButton>
                </StyledFlagsPopoverTrigger>
            </Trigger>

            <Portal>
                <StyledFlagsPopoverContent sideOffset={12} align={align}>
                    <StyledSearchFlag align="center" gap={2}>
                        <SearchLgIcon size={20} />
                        <StyledInputSearchFlag
                            type="text"
                            placeholder={t('admin_services:search_flags')}
                            value={search}
                            onChange={e => setSearch(e.currentTarget.value)}
                        />
                    </StyledSearchFlag>
                    {flagsQuery.isError ? (
                        <EmptyFlagsResult title={t('errors:default')} icon={<AlertTriangleIcon />} hasRetry />
                    ) : flagsQuery.isFetching ? (
                        <ScrollArea scrollbar={<Scrollbar />}>
                            <StyledPopoverFlagsList>
                                {Array.from(Array(4).keys()).map((_, index) => (
                                    <StyledFlagsPopoverItem key={index} justify={'space-between'} gap={2}>
                                        <Flexbox gap={2}>
                                            <Skeleton height={20} width={20} />
                                            <div>
                                                <Skeleton height={20} />
                                            </div>
                                        </Flexbox>
                                        <Skeleton height={20} width={16} />
                                    </StyledFlagsPopoverItem>
                                ))}
                            </StyledPopoverFlagsList>
                        </ScrollArea>
                    ) : flagsList.length == 0 ? (
                        <EmptyFlagsResult title={t('commons:no_results')} icon={<FileX02Icon />} hasRetry />
                    ) : (
                        <>
                            <Divider direction="horizontal" />

                            <ScrollArea scrollbar={<Scrollbar />}>
                                <StyledPopoverFlagsList>
                                    {flagsList.map(flag => (
                                        <FlagsPopoverItem
                                            sectionId={sectionId}
                                            serviceId={serviceId}
                                            editFlagServiceSection={editFlagServiceSection}
                                            key={flag.id}
                                            flag={flag}
                                            itemId={itemId}
                                            checked={selectedFlagsId.includes(flag.id)}
                                            isSection={isSection}
                                            editFlagServiceItem={editFlagServiceItem}
                                        />
                                    ))}
                                </StyledPopoverFlagsList>
                            </ScrollArea>
                        </>
                    )}
                </StyledFlagsPopoverContent>
            </Portal>
        </Root>
    )
}
