import {z} from 'zod'
import {Countries} from 'country-to-currency'
import {ResponseTypeParam} from 'src/types.ts'

export const GalleryItem = z.object({
    id: z.number().positive().int(),
    progressValue: z.number().optional(),
    blob: z.instanceof(File).optional(),
    src: z.string().url().optional(),
    presignedUrl: z.string().optional(),
    status: z.enum(['pending', 'success', 'error', 'idle']).default('idle')
})
export type GalleryItem = z.infer<typeof GalleryItem>

export const ServiceVettingStatus = z.enum(['accepted', 'declined', 'back_to_draft'])
export type ServiceVettingStatus = z.infer<typeof ServiceVettingStatus>

export const Flag = z.object({
    id: z.number(),
    type: z.enum([
        'description',
        'general',
        'location',
        'title',
        'gallery',
        'gallery_image',
        'host_expertise',
        'profile_photo',
        'decline_expertise',
        'decline_offering'
    ]),
    key_name: z.enum([
        'alcohol_without_license',
        'blurry_profile_picture',
        'not_enough_online_presence',
        'not_enough_experience_years',
        'policy_violation',
        'expertise_unrelated',
        'alcohol_smoking',
        'animal_welfare_violations',
        'black_white',
        'blurry',
        'cannot_be_cropped_vertically',
        'collages',
        'duplicate',
        'heavy_filters_applied',
        'hosts_experience_unclear',
        'inappropriate_content',
        'insufficient_formal_experience',
        'irrelevant_lacking_context',
        'lack_photo_variety',
        'misleading_inaccurate',
        'missing_career_achievements',
        'multiple_countries_selected',
        'passport_or_logo',
        'photo_angle_awkward',
        'poor_lighting_background',
        'reputation_proof_insufficient',
        'selfie_style',
        'service_description_unclear',
        'spelling_grammatical_errors',
        'stock_photos_or_screenshots',
        'stretched_distorted',
        'text_graphics_overlaid',
        'title_needs_update',
        'too_dark'
    ])
})
export type Flag = z.infer<typeof Flag>

export const FlagsSearchParams = z.object({
    types: Flag.shape['type'].array().nullish(),
    ...ResponseTypeParam.shape
})
export type FlagsSearchParams = z.infer<typeof FlagsSearchParams>

export type FlagWithLabel = Flag & {label: string}

export const OfferingTypes = z.enum(['basic', 'standard', 'luxe', 'other'])
export type OfferingTypes = z.infer<typeof OfferingTypes>

export const ServiceStatus = z.enum([
    'approved',
    'declined',
    'in_progress',
    'under_review',
    'pending_id_verification',
    'update_required',
    'waitlisted'
])
export type ServiceStatus = z.infer<typeof ServiceStatus>

export const AdminService = z.object({
    address: z.string().nullable(),
    country: z
        .custom<Countries>(value => {
            return typeof value === 'string'
        })
        .nullable(),
    city: z.string().nullable(),
    submission_cities: z
        .object({
            city: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                country: z.custom<Countries>(value => {
                    return typeof value === 'string'
                }),
                country_iso: z.string(),
                state: z.string().nullable()
            })
        })
        .array(),
    zip_code: z.string().nullable(),
    state: z.string().nullable(),
    latitude: z.string().nullable(),
    longitude: z.string().nullable(),
    gallery: z.object({
        id: z.number(),
        flags: Flag.array(),
        images: z
            .object({
                submission_id: z.number(),
                id: z.number().int().positive(),
                name: z.string(),
                url: z.string().url(),
                is_approved: z.boolean().nullable(),
                flags: Flag.array()
            })
            .array()
    }),
    category: z
        .object({
            key_name: z.enum([
                'photography',
                'private_chef',
                'prepared_meals',
                'catering',
                'personal_training',
                'massage',
                'spa_treatments',
                'haircare',
                'makeup',
                'nails',
                'generic' //TODO remove 'generic' when the category step is enabled again
            ])
        })
        .nullable(),
    expertise: z.object({
        id: z.number().positive(),
        status: ServiceVettingStatus.nullable(),
        flags: Flag.array(),
        question_answer: z.array(
            z
                .object({
                    id: z.number(),
                    answer: z.string(),
                    flags: Flag.array(),
                    question_key_name: z.enum([
                        'experience_years',
                        'summary',
                        'highlights',
                        'hosting_modality',
                        'co_hosts',
                        'inspiration',
                        'background',
                        'accolades',
                        'category',
                        'career',
                        'expertise',
                        'background',
                        'about_yourself',
                        'location',
                        'location_notes',
                        'age_limit',
                        'age',
                        'bring_anything',
                        'what_bring',
                        'language',
                        'main_language',
                        'extra_languages',
                        'group_min_size',
                        'group_max_size',
                        'main_language',
                        'group_min_size',
                        'group_max_size',
                        'profile_photo'
                    ])
                })
                .or(
                    z.object({
                        id: z.number(),
                        answer: z.enum(['individual', 'business']),
                        question_key_name: z.literal('business_type'),
                        flags: Flag.array()
                    })
                )
                .or(
                    z.object({
                        id: z.number(),
                        flags: Flag.array(),
                        answer: z.string().array(),
                        question_key_name: z.literal('links')
                    })
                )
                .or(
                    z.object({
                        id: z.number(),
                        answer: z.string().array(),
                        flags: Flag.array(),
                        question_key_name: z.literal('extra_languages')
                    })
                )
                .or(
                    z.object({
                        id: z.number(),
                        answer: z.enum(['24_hours', '7_days']),
                        question_key_name: z.literal('cancellation_policy'),
                        flags: Flag.array()
                    })
                )
                .or(
                    z.object({
                        id: z.number(),
                        answer: z.string().array(),
                        flags: Flag.array(),
                        question_key_name: z.literal('cities')
                    })
                )
        )
    }),
    id: z.number().int().positive(),
    status: ServiceStatus,
    step: z
        .object({
            step: z.enum(['get_started', 'offerings', 'service_location', 'gallery', 'suggestions']),
            sub_step: z.null()
        })
        .or(
            z.object({
                step: z.literal('host_expertise'),
                sub_step: z.enum(['category', 'career', 'expertise', 'about_yourself'])
            })
        )
        .or(
            z.object({
                step: z.literal('other_details'),
                sub_step: z.enum(['about_guests', 'more_details', 'terms'])
            })
        ),
    minimum_image_required: z.number().nullable(),
    airbnb_link: z.string().url().nullish(), // todo: it will be used in near feature
    created_at: z.string().min(1).optional(),
    host: z.object({
        first_name: z.string(),
        profile_photo_url: z.string().url().nullable()
    }),
    offerings: z.array(
        z.object({
            id: z.number().positive(),
            status: ServiceVettingStatus.nullable(),
            submission_id: z.number(),
            flags: Flag.array(),
            type: OfferingTypes,
            question_answer: z
                .object({
                    id: z.number().positive(),
                    answer: z.string(),
                    flags: Flag.array(),
                    question_key_name: z.enum([
                        'currency',
                        'description',
                        'duration',
                        'price_per_guest',
                        'title',
                        'main_photo',
                        'price_per_group',
                        'meeting_point'
                    ]),
                    gallery_image: z.object({id: GalleryItem.shape['id']}).nullable()
                })
                .array()
        })
    )
})
export type AdminService = z.infer<typeof AdminService>

export const AdminServiceAnswer = z.object({answer: z.string(), id: z.number(), flags: Flag.array()})
export type AdminServiceAnswer = z.infer<typeof AdminServiceAnswer>

export const AdminServiceUploadedMedia = z.object({
    id: z.number(),
    url: z.string().url()
})
export type AdminServiceUploadedMedia = z.infer<typeof AdminServiceUploadedMedia>

export const EditFlagsSectionUrlParam = z.enum(['expertise', 'offering', 'gallery'])
export type EditFlagsSectionUrlParam = z.infer<typeof EditFlagsSectionUrlParam>

export const EditFlagsItemUrlParam = z.enum(['images', 'question-answer'])
export type EditFlagsItemUrlParam = z.infer<typeof EditFlagsItemUrlParam>

export const OfferingCardQuestions = z.object({
    title: AdminServiceAnswer,
    price_per_guest: AdminServiceAnswer,
    price_per_group: AdminServiceAnswer.optional(),
    currency: AdminServiceAnswer,
    duration: AdminServiceAnswer,
    description: AdminServiceAnswer,
    main_photo: AdminServiceAnswer.optional()
})
export type OfferingCardQuestions = z.infer<typeof OfferingCardQuestions>

export const ServiceVersion = z.object({
    id: z.number(),
    status: ServiceStatus,
    created_at: z.string()
})
export type ServiceVersion = z.infer<typeof ServiceVersion>
