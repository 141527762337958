import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminImageCardFrame = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 5}px;
        overflow: hidden;
    `
)

export const StyledAdminServiceImageCard = styled.img`
    aspect-ratio: 1 / 1;
    object-fit: cover;
    width: 100%;
    border-radius: 8px;
`

export const StyledAdminServiceImageTitle = styled.h4(
    ({theme: {typography}}) => css`
        ${typography.textXs};
        font-weight: 500;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    `
)
