import {Content} from '@radix-ui/react-popover'
import styled, {css} from 'styled-components'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {Button} from 'src/components/button/Button.tsx'

export const StyledFlagsPopoverTrigger = styled.div(
    () => css`
        width: fit-content;
        cursor: pointer;
    `
)

export const StyledFlagsPopoverContent = styled(Content)(
    ({theme: {palette, spacing, shadows, zIndex}}) => css`
        width: 320px;
        background-color: ${palette.neutral.white};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 8px;
        padding: ${spacing}px 0;
        box-shadow: ${shadows.lg};
        z-index: ${zIndex.modal};
    `
)

export const StyledEmptyFlagsResult = styled(Flexbox)(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 8}px 0;
        & h3 {
            ${typography.textLg};
            font-weight: 600;
            color: ${palette.neutral['900']};
            text-align: center;
        }
    `
)

export const StyledSearchFlag = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 3}px ${spacing * 3.5}px;
    `
)

export const StyledInputSearchFlag = styled.input(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        width: 100%;
    `
)

export const StyledPopoverFlagsList = styled.div`
    height: 280px;
    width: 100%;
`

export const StyledFlagsPopoverItem = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        ${typography.textSm};
        white-space: break-spaces;
        font-weight: 500;
        color: ${palette.neutral['700']};
        padding: ${spacing * 2}px ${spacing * 3}px;
        transition: ease-in-out 150ms;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    `
)
export const StyledFlagsPopoverTriggerButton = styled(Button)(
    ({theme: {spacing, typography}}) => css`
        gap: ${spacing * 0.5}px;
        padding: ${spacing * 0.75}px ${spacing * 1.5}px;
        ${typography.textXs};
        font-weight: 500;
        border-radius: 6px;
        & svg {
            height: 14px;
            width: 14px;
        }
    `
)
