import axios, {RawAxiosRequestHeaders} from 'axios'
import {ResponseTypeParam} from 'src/types'
import {
    EditFlagsItemUrlParam,
    EditFlagsSectionUrlParam,
    FlagsSearchParams,
    ServiceVettingStatus
} from 'src/features/admin-services/types.ts'

/**
 * httpGetAdminService
 * Get informations from a single submission
 */
type HttpGetAdminService = {
    urlParams: {serviceId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpGetAdminService = ({urlParams, params, headers}: HttpGetAdminService) =>
    axios.get(`mediabox-admin/services/${urlParams.serviceId}`, {
        params,
        headers
    })

/**
 * httpUpdateExpertiseStatus
 * Update the  expertise status of a submission
 */
export type HttpUpdateExpertiseStatus = {
    urlParams: {serviceId: number; expertiseId: number}
    payload: {status: ServiceVettingStatus}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpUpdateExpertiseStatus = ({urlParams, payload, headers, params}: HttpUpdateExpertiseStatus) =>
    axios.patch(
        `mediabox-admin/services/${urlParams.serviceId}/expertise/${urlParams.expertiseId}/update-status`,
        {
            ...params,
            ...payload
        },
        {headers}
    )

/**
 * httpUpdateOfferingStatus
 * Update the  offering status of a submission
 */
export type HttpUpdateOfferingStatus = {
    urlParams: {serviceId: number; offeringId: number}
    payload: {status: ServiceVettingStatus}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpUpdateOfferingStatus = ({urlParams, payload, headers, params}: HttpUpdateOfferingStatus) =>
    axios.patch(
        `mediabox-admin/services/${urlParams.serviceId}/offering/${urlParams.offeringId}/update-status`,
        {
            ...params,
            ...payload
        },
        {headers}
    )

/**
 * httpUpdateOfferingStatus
 * Update the gallery item approved value
 */
export type HttpUpdateIsApprovedGalleryImg = {
    urlParams: {serviceId: number; galleryId: number; imgId: number}
    payload: {is_approved: boolean}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpUpdateIsApprovedGalleryImg = ({urlParams, payload, headers, params}: HttpUpdateIsApprovedGalleryImg) =>
    axios.patch(
        `mediabox-admin/services/${urlParams.serviceId}/gallery/${urlParams.galleryId}/images/${urlParams.imgId}/is-approved`,
        {
            ...params,
            ...payload
        },
        {headers}
    )

/**
 * httpGetFlags
 * get all flags
 */
export type HttpGetFlags = {
    params: FlagsSearchParams
    headers: RawAxiosRequestHeaders
}

export const httpGetFlags = ({headers, params}: HttpGetFlags) =>
    axios.get(`/mediabox-admin/services/flags`, {
        params,
        headers
    })

/**
 * httpAddExpertiseFlags
 * Update the expertise question flags
 */
export type HttpEditItemFlags = {
    urlParams: {
        serviceId: number
        sectionId?: number
        itemId: number
        section: EditFlagsSectionUrlParam
        item: EditFlagsItemUrlParam
    }
    payload: {flags: number[]}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpAddServiceItemFlags = ({headers, params, payload, urlParams}: HttpEditItemFlags) =>
    axios.post(
        `mediabox-admin/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        payload,
        {headers, params}
    )

export const httpRemoveServiceItemFlags = ({headers, params, payload, urlParams}: HttpEditItemFlags) =>
    axios.delete(
        `mediabox-admin/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.sectionId}/${urlParams.item}/${urlParams.itemId}/flags`,
        {
            params: {
                ...params,
                ...payload
            },
            headers
        }
    )

export type HttpEditServiceSectionFlags = {
    urlParams: {
        serviceId: number
        itemId: number
        section: EditFlagsSectionUrlParam
    }
    payload: {flags: number[]}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpAddServiceSectionFlags = ({headers, params, payload, urlParams}: HttpEditServiceSectionFlags) =>
    axios.post(
        `mediabox-admin/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.itemId}/flags`,
        payload,
        {headers, params}
    )

export const httpRemoveSectionFlags = ({headers, params, payload, urlParams}: HttpEditServiceSectionFlags) =>
    axios.delete(`mediabox-admin/services/${urlParams.serviceId}/${urlParams.section}/${urlParams.itemId}/flags`, {
        params: {
            ...params,
            ...payload
        },
        headers
    })

export type HttpSubmitService = {
    urlParams: {
        serviceId: number
    }
    payload: {is_waitlisted: boolean}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpSubmitService = ({headers, params, payload, urlParams}: HttpSubmitService) =>
    axios.post(`mediabox-admin/services/${urlParams.serviceId}/submit`, payload, {headers, params})

export type HttpGetAdminServiceVersions = {
    urlParams: {serviceId: number}
    params: ResponseTypeParam
    headers: RawAxiosRequestHeaders
}

export const httpGetAdminServiceVersions = ({urlParams, params, headers}: HttpGetAdminServiceVersions) =>
    axios.get(`mediabox-admin/services/${urlParams.serviceId}/versions`, {
        params,
        headers
    })
