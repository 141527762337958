import {AdminService} from 'src/features/admin-services/types'
import {Container} from 'src/components/container/Container'
import {
    AdminServiceDisabledContainer,
    AdminServiceParagraph,
    AdminServiceSectionTitle
} from '../admin-services-atoms/AdminServicesAtoms'
import {useTranslation} from 'react-i18next'
import {useMemo} from 'react'
import {Divider} from 'src/components/divider/Divider'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {
    AdminServiceVettingOfferingCard,
    OfferingCard
} from 'src/features/admin-services/components/admin-service-vetting-offering-card/AdminServiceVettingOfferingCard.tsx'
import {remapServiceOfferings} from 'src/features/admin-services/utils.ts'
import {CheckIcon} from 'src/components/icon'
import {useTheme} from 'styled-components'

export const AdminServiceVettingOfferings = ({adminService}: {adminService: AdminService}) => {
    const {t} = useTranslation()

    const primaryOfferings: OfferingCard[] = useMemo(
        () => remapServiceOfferings(adminService.offerings.filter(offering => offering.type != 'other')),
        [adminService.offerings]
    )

    const otherOfferings: OfferingCard[] = useMemo(
        () => remapServiceOfferings(adminService.offerings.filter(offering => offering.type == 'other')),
        [adminService.offerings]
    )
    const {palette} = useTheme()

    return (
        <Container fullHeight={false}>
            <Flexbox fullWidth justify="space-between" align="center">
                <AdminServiceSectionTitle>{t('admin_services:offerings')}</AdminServiceSectionTitle>
                <AdminServiceParagraph>
                    {adminService.offerings.filter(offering => !offering.status).length > 0 ? (
                        t('admin_services:offerings_remaining', {
                            value: adminService.offerings.filter(offering => !offering.status).length
                        })
                    ) : (
                        <Flexbox render={'span'} gap={1}>
                            <CheckIcon size={20} color={palette.success[600]} />
                            {t('admin_services:reviewed')}
                        </Flexbox>
                    )}
                </AdminServiceParagraph>
            </Flexbox>
            <Divider direction="horizontal" endSpacing={6} startSpacing={6} />
            <AdminServiceDisabledContainer $isDisabled={adminService.expertise.status == 'declined'}>
                <Flexbox direction={'column'} gap={6}>
                    {primaryOfferings.map((offering, index) => (
                        <AdminServiceVettingOfferingCard
                            disableAction={adminService.status != 'under_review'}
                            key={index}
                            offering={offering}
                            index={index}
                        />
                    ))}
                    {otherOfferings.map((offering, index) => (
                        <AdminServiceVettingOfferingCard
                            disableAction={adminService.status != 'under_review'}
                            key={index}
                            offering={offering}
                            index={index}
                        />
                    ))}
                </Flexbox>
            </AdminServiceDisabledContainer>
        </Container>
    )
}
