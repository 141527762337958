import {
    StyledSubHeaderBannerBody,
    StyledSubHeaderBannerText,
    StyledSubHeaderBannerWrapper
} from 'src/features/admin-services/components/subheader-banner/style.ts'
import {Container} from 'src/components/container/Container.tsx'
import {IconContainer} from 'src/components/icon-container/IconContainer.tsx'
import {AlertCircleIcon} from 'src/components/icon'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

export const SubHeaderBanner: FC = () => {
    const {t} = useTranslation()
    return (
        <StyledSubHeaderBannerWrapper>
            <Container>
                <StyledSubHeaderBannerBody align={'center'} gap={3}>
                    <IconContainer variant={'danger'}>
                        <AlertCircleIcon />
                    </IconContainer>
                    <StyledSubHeaderBannerText gap={1.5}>
                        <h3>{t('admin_services:error_banner:title')}</h3>
                        <p>{t('admin_services:error_banner:subtitle')}</p>
                    </StyledSubHeaderBannerText>
                </StyledSubHeaderBannerBody>
            </Container>
        </StyledSubHeaderBannerWrapper>
    )
}

SubHeaderBanner.displayName = 'SubHeaderBanner'
