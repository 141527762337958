import styled, {css, DefaultTheme} from 'styled-components'
import {BadgeProps} from 'src/components/badge/Badge'

type StyledBadgeProps = {
    $shape: NonNullable<BadgeProps['shape']>
    $size: NonNullable<BadgeProps['size']>
    $variant: NonNullable<BadgeProps['variant']>
}

const makeBadgeBaseStyle = ({
    theme,
    $shape,
    $disabled
}: {
    theme: DefaultTheme
    $shape: StyledBadgeProps['$shape']
    $disabled: boolean
}) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: ${theme.spacing}px;
    outline-offset: -1px;
    ${$disabled &&
    css`
        cursor: not-allowed;
    `}
    ${($shape == 'rectangle' || $shape == 'roundedRectangle') &&
    css`
        height: max-content;
    `}
    ${($shape == 'square' || $shape == 'circle') &&
    css`
        flex-shrink: 0;
    `}
`

const makeBadgeSizeStyle = (theme: DefaultTheme) =>
    ({
        sm: {
            rectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2}px;
                ${theme.typography.textXs};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2}px;
                ${theme.typography.textXs};
                border-radius: 11px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 22px;
                height: 22px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 22px;
                height: 22px;
                border-radius: 11px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        },
        md: {
            rectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2.5}px;
                ${theme.typography.textSm};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing * 0.5}px ${theme.spacing * 2.5}px;
                ${theme.typography.textSm};
                border-radius: 12px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 24px;
                height: 24px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 24px;
                height: 24px;
                border-radius: 12px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        },
        lg: {
            rectangle: css`
                padding: ${theme.spacing}px ${theme.spacing * 3}px;
                ${theme.typography.textSm};
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            roundedRectangle: css`
                padding: ${theme.spacing}px ${theme.spacing * 2}px;
                ${theme.typography.textSm};
                border-radius: 14px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            square: css`
                width: 28px;
                height: 28px;
                border-radius: 6px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `,
            circle: css`
                width: 28px;
                height: 28px;
                border-radius: 14px;
                & svg {
                    width: 12px;
                    height: 12px;
                }
            `
        }
    } as const satisfies Record<
        NonNullable<StyledBadgeProps['$size']>,
        Record<NonNullable<StyledBadgeProps['$shape']>, ReturnType<typeof css>>
    >)

const makeBadgeVariantStyle = (theme: DefaultTheme, $disabled: boolean) =>
    ({
        primary: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.primary['400']};
                      background: ${theme.palette.primary['50']};
                      outline: 1px solid ${theme.palette.primary['100']};
                  `
                : css`
                      color: ${theme.palette.primary['700']};
                      background: ${theme.palette.primary['50']};
                      outline: 1px solid ${theme.palette.primary['200']};
                  `}
            & svg {
                fill: ${theme.palette.primary['500']};
            }
        `,
        neutral: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                      background: ${theme.palette.neutral['50']};
                      outline: 1px solid ${theme.palette.neutral['100']};
                  `
                : css`
                      color: ${theme.palette.neutral['700']};
                      background: ${theme.palette.neutral['50']};
                      outline: 1px solid ${theme.palette.neutral['200']};
                  `}
            & svg {
                fill: ${theme.palette.neutral['500']};
            }
        `,
        blue: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.blue['700']}${theme.opacities['50']};
                      background: ${theme.palette.blue['25']};
                      outline: 1px solid ${theme.palette.blue['100']};
                  `
                : css`
                      color: ${theme.palette.blue['700']};
                      background: ${theme.palette.blue['50']};
                      outline: 1px solid ${theme.palette.blue['200']};
                  `}
            & svg {
                fill: ${theme.palette.neutral['500']};
            }
        `,
        grayModernLight: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.neutral['400']};
                      background: ${theme.palette.grayModern['50']};
                  `
                : css`
                      color: ${theme.palette.neutral['700']};
                      background: ${theme.palette.grayModern['100']};
                  `}
        `,
        grayModernDark: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.neutral['50']};
                      background: ${theme.palette.grayModern['100']};
                  `
                : css`
                      color: ${theme.palette.neutral.white};
                      background: ${theme.palette.grayModern['600']};
                  `}
        `,
        danger: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.danger['300']};
                      background: ${theme.palette.danger['50']};
                      outline: 1px solid ${theme.palette.danger['200']};
                  `
                : css`
                      color: ${theme.palette.danger[600]};
                      background: ${theme.palette.danger['50']};
                      outline: 1px solid ${theme.palette.danger['300']};
                  `}
        `,
        warning: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.warning[300]};
                      background: ${theme.palette.warning[50]};
                      outline: 1px solid ${theme.palette.warning[200]};
                  `
                : css`
                      color: ${theme.palette.warning[600]};
                      background: ${theme.palette.warning[50]};
                      outline: 1px solid ${theme.palette.warning[300]};
                  `}
        `,
        success: css`
            ${$disabled
                ? css`
                      color: ${theme.palette.success[300]};
                      background: ${theme.palette.success[50]};
                      outline: 1px solid ${theme.palette.success[200]};
                  `
                : css`
                      color: ${theme.palette.success[600]};
                      background: ${theme.palette.success[50]};
                      outline: 1px solid ${theme.palette.success[300]};
                  `}
        `
    } as const satisfies Record<NonNullable<StyledBadgeProps['$variant']>, ReturnType<typeof css>>)

export const StyledBadge = styled.div<StyledBadgeProps & {$disabled?: boolean}>(
    ({theme, ...props}) => css`
        ${makeBadgeSizeStyle(theme)[props.$size][props.$shape]};
        ${makeBadgeVariantStyle(theme, !!props.$disabled)[props.$variant]};
        ${makeBadgeBaseStyle({theme, $shape: props.$shape, $disabled: !!props.$disabled})};
    `
)
