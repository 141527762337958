import {Modal} from 'src/components/modal/Modal.tsx'
import {ModalFooter, ModalXCloseButton} from 'src/components/modal-atoms/ModalAtoms.tsx'
import {
    StyledModalBody,
    StyledModalHeader
} from 'src/features/admin-services/components/admin-service-vetting-confirmation-modal/style.ts'
import {useTranslation} from 'react-i18next'
import {Button} from 'src/components/button/Button.tsx'
import {FC} from 'react'
import {AdminService} from 'src/features/admin-services/types.ts'
import {useSubmitService} from 'src/features/admin-services/services/useSubmitService.ts'
import {Spinner} from 'src/components/spinner/Spinner.tsx'
import {showAlert} from 'src/store/appGenerics.ts'
import {ALERT_LEVELS} from 'src/helpers/constants.ts'
// @ts-expect-error [TS7016] "We don't have types yet"
import {store} from 'src/index.js'

interface AdminServiceVettingConfirmationModalProps {
    onClose: () => void
    service: AdminService
    isWaitlisted: boolean
}
export const AdminServiceVettingConfirmationModal: FC<AdminServiceVettingConfirmationModalProps> = ({
    service,
    onClose,
    isWaitlisted
}) => {
    const {t} = useTranslation()
    const submitQuery = useSubmitService(
        {serviceId: service.id},
        {
            onSettled: () => onClose(),
            onSuccess: () =>
                store.dispatch(
                    showAlert({
                        message: t('admin_services:confirmation_modal:submit_success'),
                        level: ALERT_LEVELS.SUCCESS
                    })
                )
        }
    )
    const getSubtitleByService = () => {
        if (service.expertise.status == 'declined') {
            return t('admin_services:confirmation_modal:submission_rejected')
        } else if (
            service.expertise.status == 'back_to_draft' ||
            service.offerings.some(offering => offering.status == 'declined' || offering.status == 'back_to_draft') ||
            service.gallery.images.every(image => !image.is_approved)
        ) {
            return t('admin_services:confirmation_modal:submission_back_to_draft')
        } else {
            return t('admin_services:confirmation_modal:submission_accepted')
        }
    }
    return (
        <Modal width={480}>
            <StyledModalHeader>
                <ModalXCloseButton onClick={onClose} />
                <p>{t('admin_services:confirmation_modal:title')}</p>
            </StyledModalHeader>
            <StyledModalBody>
                <h3>{getSubtitleByService()}</h3>
                <p>{t('admin_services:confirmation_modal:description')}</p>
            </StyledModalBody>
            <ModalFooter>
                <Button variant={'tertiary'} onClick={onClose}>
                    {t('admin_services:confirmation_modal:cancel')}
                </Button>

                <Button
                    variant={'primaryGrayModern'}
                    onClick={() => submitQuery.mutate({is_waitlisted: isWaitlisted})}
                    disabled={submitQuery.isLoading}
                >
                    {t('admin_services:confirmation_modal:confirm')}
                    {submitQuery.isLoading && <Spinner />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
