import {SelectProvider} from '@ariakit/react'
import {Flexbox} from 'src/components/flexbox/Flexbox.tsx'
import {SelectList, SelectPopover} from 'src/components/select-atoms/SelectAtoms.tsx'
import {useGetServiceVersions} from 'src/features/admin-services/services/useGetServiceVersions.ts'
import {
    StyledSelectItem,
    StyledSelectTrigger
} from 'src/features/admin-services/components/admin-service-version-select/style.ts'
import {InputHelpText} from 'src/components/input-help-text/InputHelpText.tsx'
import {useTranslation} from 'react-i18next'
import {ServiceStatus} from 'src/features/admin-services/types.ts'
import {Badge} from 'src/components/badge/Badge.tsx'
import {ReactElement} from 'react'
import dayjs from 'dayjs'
import {Skeleton} from 'src/components/skeleton/Skeleton.tsx'
import {generatePath, useNavigate} from 'react-router'
import {ROUTES} from 'src/helpers/constants.ts'

export const AdminServiceVersionSelect = ({serviceId}: {serviceId: number}) => {
    const {t} = useTranslation()
    const flagsQuery = useGetServiceVersions({
        serviceId: serviceId
    })

    const versionStatusToBadge = (status: ServiceStatus): ReactElement => {
        switch (status) {
            case 'approved':
                return (
                    <Badge size={'sm'} variant={'success'}>
                        {t(`admin_services:service_status:${status}`)}
                    </Badge>
                )
            case 'declined':
                return (
                    <Badge size={'sm'} variant={'danger'}>
                        {t(`admin_services:service_status:${status}`)}
                    </Badge>
                )
            case 'update_required':
                return (
                    <Badge size={'sm'} variant={'warning'}>
                        {t(`admin_services:service_status:${status}`)}
                    </Badge>
                )
            default:
                return (
                    <Badge size={'sm'} variant={'neutral'}>
                        {t(`admin_services:service_status:${status}`)}
                    </Badge>
                )
        }
    }
    const navigate = useNavigate()

    return (
        <SelectProvider
            defaultValue={serviceId.toString()}
            setValue={value => navigate(generatePath(ROUTES.ADMIN_SERVICE_VETTING, {serviceId: value as string}))}
        >
            {flagsQuery.isLoading ? (
                <Flexbox gap={2} align={'center'}>
                    <Flexbox gap={2}>
                        <Skeleton height={20} width={100} />
                        <Skeleton height={20} width={100} />
                    </Flexbox>
                    <Skeleton height={14} width={14} />
                </Flexbox>
            ) : (
                <StyledSelectTrigger
                    variant={'ghost'}
                    shape={'text'}
                    displayValue={value => {
                        const version = flagsQuery.data?.find(version => version.id.toString() == value)
                        const index = flagsQuery.data?.findIndex(version => version.id.toString() == value)
                        return (
                            <Flexbox>
                                {version && index != undefined && (
                                    <Flexbox gap={2}>
                                        <h4>{t('admin_services:submission_number', {value: index + 1})}</h4>
                                        {versionStatusToBadge(version.status)}
                                    </Flexbox>
                                )}
                            </Flexbox>
                        )
                    }}
                />
            )}
            <SelectPopover portal sameWidth={false}>
                {flagsQuery.isError ? (
                    <Flexbox justify="center">
                        <InputHelpText error={t('errors:default')} />
                    </Flexbox>
                ) : (
                    <SelectList>
                        {flagsQuery.remappedData.map((value, index) => (
                            <StyledSelectItem value={value.id.toString()} key={value.id}>
                                <h4>{t('admin_services:submission_number', {value: index + 1})}</h4>
                                <p>{dayjs(value.created_at).format('ll')}</p>
                                {versionStatusToBadge(value.status)}
                            </StyledSelectItem>
                        ))}
                    </SelectList>
                )}
            </SelectPopover>
        </SelectProvider>
    )
}
