import {useTranslation} from 'react-i18next'
import {FC, ReactElement} from 'react'
import {StyledEmptyFlagsResult} from 'src/features/admin-services/components/flags-popover/style.ts'
import {IconContainer} from 'src/components/icon-container/IconContainer.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useGetFlags} from 'src/features/admin-services/services/useGetFlags.ts'

interface EmptyFlagsResultProps {
    title: string
    hasRetry: boolean
    icon?: ReactElement
}

export const EmptyFlagsResult: FC<EmptyFlagsResultProps> = ({title, icon, hasRetry}) => {
    const {t} = useTranslation()
    const {refetch, isRefetching} = useGetFlags(
        {
            response_type: 'extended'
        },
        {enabled: false}
    )

    return (
        <StyledEmptyFlagsResult align="center" direction="column" gap={4}>
            {icon && <IconContainer>{icon}</IconContainer>}
            <h3>{title}</h3>
            {hasRetry && (
                <Button onClick={() => refetch()} disabled={isRefetching}>
                    {t('commons:retry')}
                </Button>
            )}
        </StyledEmptyFlagsResult>
    )
}
