import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {QUERY_KEYS} from 'src/queryClient'
import {HttpGetFlags, httpGetFlags} from './adminServices.http'
import {Flag} from 'src/features/admin-services/types'
import {z} from 'zod'

export const useGetFlags = (params: HttpGetFlags['params'], options?: UseQueryOptions<Flag[]>) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.FLAGS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetFlags({
                    params,
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: z.array(Flag),
                onZodError: captureException
            }),
        ...options
    })
}
