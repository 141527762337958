import {useTranslation} from 'react-i18next'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {Container} from 'src/components/container/Container'
import {AdminService} from 'src/features/admin-services/types'
import {
    StyledAdminServiceVettingHeroCategory,
    StyledAdminServiceVettingHeroTitle,
    StyledAdminServiceVettingHeroWrapper
} from './style'
import {categoryToLabel, checkErrors} from 'src/features/admin-services/utils'
import {Checkbox} from 'src/components/checkbox/Checkbox.tsx'
import {Button} from 'src/components/button/Button.tsx'
import {useAdminServiceVettingError} from 'src/features/admin-services/store/useAdminServiceVettingError.ts'
import {SubHeaderBanner} from 'src/features/admin-services/components/subheader-banner/SubHeaderBanner.tsx'
import {ObjectValues} from 'src/helpers/helpers.ts'
import {AdminServiceVettingConfirmationModal} from 'src/features/admin-services/components/admin-service-vetting-confirmation-modal/AdminServiceVettingConfirmationModal.tsx'
import {useMemo, useState} from 'react'
import {AdminServiceVersionSelect} from 'src/features/admin-services/components/admin-service-version-select/AdminServiceVersionSelect.tsx'

export const AdminServiceVettingHero = ({adminService}: {adminService: AdminService}) => {
    const {t} = useTranslation()

    const setIsTouched = useAdminServiceVettingError(state => state.setIsTouched)
    const errors = useAdminServiceVettingError(state => state.errors)
    const setErrors = useAdminServiceVettingError(state => state.setErrors)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [isWaitlisted, setIsWaitlisted] = useState(adminService.status == 'waitlisted')
    const isValid = useMemo(() => ObjectValues(errors).every(error => error.length == 0), [errors])
    const onClickSubmit = () => {
        setIsTouched(true)
        const errorCheck = checkErrors(adminService)
        setErrors(errorCheck.errors)
        if (errorCheck.isValid) {
            setShowConfirmationModal(true)
        }
    }

    return (
        <Flexbox fullWidth direction={'column'}>
            <Container fullHeight={false}>
                <StyledAdminServiceVettingHeroWrapper justify="space-between" gap={6}>
                    <Flexbox gap={2} direction="column">
                        <StyledAdminServiceVettingHeroTitle>
                            {t('admin_services:title')}
                        </StyledAdminServiceVettingHeroTitle>
                        <Flexbox gap={2.5} align="center">
                            {adminService.category?.key_name && (
                                <StyledAdminServiceVettingHeroCategory>
                                    {t('admin_services:category_value', {
                                        category: t(categoryToLabel[adminService.category.key_name])
                                    })}
                                </StyledAdminServiceVettingHeroCategory>
                            )}
                            <AdminServiceVersionSelect serviceId={adminService.id} />
                        </Flexbox>
                    </Flexbox>
                    {adminService.status == 'under_review' && (
                        <Flexbox gap={4} align="center">
                            <Checkbox
                                checked={isWaitlisted}
                                id="waitlisted"
                                label={t('statuses:waitlisted')}
                                onChange={() => setIsWaitlisted(!isWaitlisted)}
                            />
                            <Button variant="primaryGrayModern" size={'sm'} onClick={onClickSubmit}>
                                {t('commons:submit')}
                            </Button>
                        </Flexbox>
                    )}
                </StyledAdminServiceVettingHeroWrapper>
            </Container>
            {!isValid && <SubHeaderBanner />}
            {showConfirmationModal && isValid && (
                <AdminServiceVettingConfirmationModal
                    onClose={() => setShowConfirmationModal(false)}
                    service={adminService}
                    isWaitlisted={isWaitlisted}
                />
            )}
        </Flexbox>
    )
}
