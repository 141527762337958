import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledOfferingCard = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 16px;
        width: 100%;
    `
)

export const StyledOfferingPreviewImage = styled.img`
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 8px;
`

export const StyledImagePlaceholderBox = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.neutral['100']};
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 8px;
        flex-shrink: 0;

        & > svg {
            color: ${palette.neutral['400']};
        }
    `
)

export const StyledOfferingBodyContainer = styled.div(
    () => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) 264px;
        width: 100%;
        justify-items: end;
        gap: 16px;
    `
)
