import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {QUERY_KEYS} from 'src/queryClient'
import {httpGetAdminService} from './adminServices.http'
import {AdminService} from 'src/features/admin-services/types'

export const useAdminServices = (serviceId?: number, options?: UseQueryOptions<AdminService>) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')

    return useQuery({
        queryKey: [QUERY_KEYS.ADMIN_SERVICES, serviceId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminService({
                    urlParams: {serviceId: serviceId ?? raise('The service id is not provided')},
                    params: {response_type: 'extended'},
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: AdminService,
                onZodError: captureException
            }),
        ...options
    })
}
