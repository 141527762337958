import {Container} from 'src/components/container/Container'
import styled from 'styled-components'

export const StyledAdminServiceVetting = styled.div`
    overflow-y: scroll;
`

export const StyledAdminServiceVettingLoadingContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
`
