import {HTMLAttributes} from 'react'
import {
    StyledAdminServiceDisabledContainer,
    StyledAdminServiceLabelDetails,
    StyledAdminServiceParagraph,
    StyledAdminServiceParagraphTitle,
    StyledStyledAdminServiceSectionTitle
} from './style'

export const AdminServiceSectionTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledStyledAdminServiceSectionTitle {...props} />
)
export const AdminServiceParagraphTitle = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledAdminServiceParagraphTitle {...props} />
)
export const AdminServiceLabelDetails = (props: HTMLAttributes<HTMLSpanElement>) => (
    <StyledAdminServiceLabelDetails {...props} />
)
export const AdminServiceParagraph = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledAdminServiceParagraph {...props} />
)

export const AdminServiceDisabledContainer = (props: HTMLAttributes<HTMLDivElement> & {$isDisabled: boolean}) => {
    return <StyledAdminServiceDisabledContainer {...props} />
}
