import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {errorHandler, makeHttpAuthorizationHeader, raise} from 'src/helpers/helpers'
import {
    httpUpdateIsApprovedGalleryImg,
    HttpUpdateIsApprovedGalleryImg
} from 'src/features/admin-services/services/adminServices.http.ts'
import {AdminService} from 'src/features/admin-services/types.ts'
import {QUERY_KEYS} from 'src/queryClient.ts'

export const useUpdateIsApprovedGalleryImg = (
    urlParams: HttpUpdateIsApprovedGalleryImg['urlParams'],
    options?: UseMutationOptions<unknown, unknown, HttpUpdateIsApprovedGalleryImg['payload']>
) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')
    const queryClient = useQueryClient()
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateIsApprovedGalleryImg({
                urlParams,
                payload,
                params: {
                    response_type: 'small'
                },
                headers: makeHttpAuthorizationHeader(adminAccessToken)
            }),
        onError: (error, variables, context) => {
            queryClient.setQueriesData({queryKey: [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]}, context)
            errorHandler(error)
            options?.onError?.(error, variables, context)
        },
        onMutate: async variables => {
            const queryKey = [QUERY_KEYS.ADMIN_SERVICES, urlParams.serviceId]

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({queryKey})

            // Snapshot previous values
            const previousData = queryClient.getQueryData<AdminService>(queryKey)

            // Update cached data
            if (previousData) {
                queryClient.setQueriesData<AdminService>(
                    {queryKey},
                    {
                        ...previousData,
                        gallery: {
                            ...previousData.gallery,
                            images: previousData.gallery.images.map(image => {
                                if (image.id == urlParams.imgId) {
                                    return {...image, is_approved: variables.is_approved}
                                }
                                return image
                            })
                        }
                    }
                )
            }
            return previousData
        }
    })
}
