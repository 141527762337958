import {Button} from 'src/components/button/Button'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import styled, {css} from 'styled-components'

export const StyledAdminServiceVettingHeroWrapper = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 8}px 0;
    `
)

export const StyledAdminServiceVettingHeroTitle = styled.h2(
    ({theme: {typography}}) => css`
        ${typography.displaySm};
        font-weight: 700;
    `
)

export const StyledAdminServiceVettingHeroCategory = styled.p(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['700']};
    `
)

export const StyledAdminServiceVettingDropdownTrigger = styled(Button)(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 400;
        cursor: default;
    `
)
