import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {Container} from 'src/components/container/Container'
import {ErrorBox} from 'src/components/error-box/ErrorBox'
import {AlertTriangleIcon} from 'src/components/icon'
import {Spinner} from 'src/components/spinner/Spinner'
import {useAdminServices} from 'src/features/admin-services/services/useAdminService'
import {useURLParsedParams} from 'src/hooks/useURLParsedParams'
import {z} from 'zod'
import {StyledAdminServiceVetting, StyledAdminServiceVettingLoadingContainer} from './style'
import {AdminServiceVettingHero} from 'src/features/admin-services/components/admin-service-vetting-hero/AdminServiceVettingHero'
import {Helmet} from 'react-helmet'
import {AdminServiceVettingHostExpertise} from 'src/features/admin-services/components/admin-service-vetting-host-expertise/AdminServiceVettingHostExpertise'
import {AdminServiceVettingOfferings} from 'src/features/admin-services/components/admin-service-vetting-offerings/AdminServiceVettingOfferings'
import {Flexbox} from 'src/components/flexbox/Flexbox'
import {AdminServiceVettingGallery} from 'src/features/admin-services/components/admin-service-vetting-gallery/AdminServiceVettingGallery'
import {useAdminServiceVettingError} from 'src/features/admin-services/store/useAdminServiceVettingError.ts'
import {useEffect} from 'react'
import {checkErrors} from 'src/features/admin-services/utils.ts'

export const AdminServiceVetting = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(z.object({serviceId: z.coerce.number()}))
    const setErrors = useAdminServiceVettingError(state => state.setErrors)
    const resetErrors = useAdminServiceVettingError(state => state.reset)
    const isTouched = useAdminServiceVettingError(state => state.isTouched)
    const {
        data: adminService,
        isLoading,
        error
    } = useAdminServices(urlParams.data?.serviceId, {
        enabled: urlParams.success
    })

    useEffect(() => {
        if (isTouched && adminService) {
            const errorCheck = checkErrors(adminService)
            setErrors(errorCheck.errors)
        }
        return () => resetErrors()
    }, [adminService])

    if (isLoading) {
        return (
            <StyledAdminServiceVettingLoadingContainer fullHeight fullWidth>
                <Spinner size={40} />
            </StyledAdminServiceVettingLoadingContainer>
        )
    }
    if (urlParams.error || (error instanceof AxiosError && error.response?.status == 404)) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('host_approval_task:not_found')} />
            </Container>
        )
    }
    if (error || !adminService) {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('errors:default')} />
            </Container>
        )
    }

    if (adminService.status == 'in_progress') {
        return (
            <Container>
                <ErrorBox icon={<AlertTriangleIcon />} title={t('admin_services:service_still_in_progress')} />
            </Container>
        )
    }

    return (
        <StyledAdminServiceVetting>
            <Helmet
                title={t('admin_services:meta_title', {
                    number: adminService.id
                })}
            />

            <Flexbox direction="column" gap={7}>
                <AdminServiceVettingHero adminService={adminService} />
                <AdminServiceVettingHostExpertise adminService={adminService} />
                <AdminServiceVettingOfferings adminService={adminService} />
                <AdminServiceVettingGallery adminService={adminService} />
            </Flexbox>
        </StyledAdminServiceVetting>
    )
}
