import {captureException} from '@sentry/react'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {useUserStore} from 'src/features/user/store'
import {makeHttpAuthorizationHeader, parseAxiosPromise, raise} from 'src/helpers/helpers'
import {QUERY_KEYS} from 'src/queryClient'
import {httpGetAdminServiceVersions, HttpGetAdminServiceVersions} from './adminServices.http'
import {ServiceVersion} from 'src/features/admin-services/types'
import {z} from 'zod'

export const useGetServiceVersions = (
    urlParams: HttpGetAdminServiceVersions['urlParams'],
    options?: UseQueryOptions<ServiceVersion[]>
) => {
    const adminAccessToken = useUserStore(store => store.adminAccessToken) ?? raise('accessToken is null')

    const query = useQuery({
        queryKey: [QUERY_KEYS.SERVICE_VERSIONS, urlParams],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAdminServiceVersions({
                    params: {response_type: 'extended'},
                    urlParams,
                    headers: makeHttpAuthorizationHeader(adminAccessToken)
                }),
                responseShape: z.array(ServiceVersion),
                onZodError: captureException
            }),
        ...options
    })

    return {...query, remappedData: query.data ? query.data : []}
}
